/* 
  Overwrite preflight for post-content div
  https://v1.tailwindcss.com/docs/preflight 
*/

#post-content > ul,
#post-content > * > ul { 
  list-style-type: disc; 
  list-style-position: inside; 
}
#post-content > ol,
#post-content > * > ol { 
  list-style-type: decimal; 
  list-style-position: inside; 
}
#post-content > ul ul,
#post-content > * > ul ul,
#post-content > ol ul,
#post-content > * > ol ul { 
  list-style-type: circle; 
  list-style-position: inside; 
  margin-left: 15px; 
}
#post-content > ol ol,
#post-content > * > ol ol,
#post-content > ul ol,
#post-content > * > ul ol { 
  list-style-type: lower-latin; 
  list-style-position: inside; 
  margin-left: 15px; 
}

#post-content > ul,
#post-content > * > ul,
#post-content > ol,
#post-content > * > ol {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 2.5em;
}

/* Revise margins for blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre */
#post-content > blockquote,
#post-content > * > blockquote {
  margin: 1em 40px;
  padding: 0.5em 10px;
  border-left: 2px solid #ccc;
}

#post-content > dl,
#post-content > * > dl {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

#post-content > dd,
#post-content > * > dd {
  margin-inline-start: 40px;
}

#post-content > h1,
#post-content > * > h1 {
  font-size: 2em;
  font-weight: bold;
}

#post-content > h2,
#post-content > * > h2 {
  font-size: 1.5em;
  font-weight: bold;
}

#post-content > h3,
#post-content > * > h3 {
  font-size: 1.17em;
  font-weight: bold;
}

#post-content > h4,
#post-content > * > h4 {
  font-size: 1em;
  font-weight: bold;
}

#post-content > h5,
#post-content > * > h5 {
  font-size: 0.83em;
  font-weight: bold;
}

#post-content > h6,
#post-content > * > h6 {
  font-size: 0.67em;
  font-weight: bold;
}

#post-content > hr,
#post-content > * > hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 100%;
  max-width: 100%;
  height: 0;
}

#post-content > figure,
#post-content > * > figure {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

#post-content > p,
#post-content > * > p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

#post-content > pre,
#post-content > * > pre {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0;
}



/* #post-content > table,
#post-content > * > table,
#post-content > * > table * > th,
#post-content > * > table * > td {
  border-width: 1px;
  border-style: solid;
  border-color: black;
} */

/* blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
} */